
import axios from 'axios';
export default {
    async CustomersReport(params) {
        return await axios.post(`reports/customers`, params)
    },
    async reportOrderItems(params) {
        return await axios.post(`reports/orderItems`, params)
    },
    async itemByMonthReport() {
        return await axios.get(`reports/itemByMonth`)
    },
    async moneyByMonthReport(params) {
        return await axios.post(`reports/moneyByMonth`, params)
    },
    async reportItems(params) {
        return await axios.post(`reports/items`, params)
    },
    async reportUsers(params) {
        return await axios.post(`reports/users`, params)
    },
    async reportPacked(params) {
        return await axios.post(`reports/packed`, params)
    },
    
}
